import { Typography, CardActionArea, Box } from '@mui/material';
import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';

function DepcPageCard(props){

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    maxHeight: '70vh',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': { width: 0 },
  };


  const { sx, student } = props;
  //   const [windowWidth, setWidth] = useState(window.innerWidth);
  //   window.addEventListener('resize', () => {
  //     setWidth(window.innerWidth);
  //   });
  // const [showContent, changeContent] = useState(false);
  let imgbgurl=null;
  if (student.student.picture){
    const imgbgidx = student.student.picture.slice(32).slice(0, -17);
    imgbgurl = 'https://drive.google.com/uc?id=' + imgbgidx;
  }
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return(<>
    <Card sx={{ ...sx }}>
      <CardActionArea sx={{ height:'100%' }} onClick={handleOpen}>
        <CardMedia
          component="img"
          image={imgbgurl}
          height="70%"
          alt={student.student.name}
          // sx={{ height:'50% !important' }}
        />
        <CardContent sx={{ height:'30%' }}>
          <Typography gutterBottom variant="body1" component="div" sx={{ m:'0' }}>
            {student.student.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {student.department_from.department_acronym} to {student.department_to.department_acronym}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {student.brief}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={open}>
        <Box sx={style}>
          <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center' }}>
            <Box sx={{ display:'flex', flexDirection:'column', width:'80%' }}>
              <Typography id="transition-modal-title" variant="h4" component="h2">
                {student.student.name}
              </Typography>
              {
                student.department_from.department_acronym && student.department_to.department_acronym ? (
                  <Box sx={{ mt: 3 }}>
                    <Typography id="transition-modal-description" variant="h6">
                      Departments
                    </Typography>  
                    <Typography id="transition-modal-description" sx={{ mt: 0.5 }}>
                      {student.department_from.department_acronym} to {student.department_to.department_acronym}
                    </Typography>
                  </Box>
                ):null
              }
              {
                student.body ? (
                  <Box sx={{ mt: 3 }}>
                    <Typography id="transition-modal-description" variant="h6">
                      Description
                    </Typography>  
                    <Typography id="transition-modal-description" sx={{ mt: 0.5 }}>
                      {student.body}
                    </Typography>
                  </Box>
                ):null
              }       
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  </>
  );
}

export default DepcPageCard;
