import React, { useEffect, useMemo, useState } from 'react';
import { Box, Chip, Container, Grid, IconButton, Stack, Typography, ButtonBase } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { initialiseCompetitionTags } from '../reducers/competitionTagReducer';
import { Launch } from '@mui/icons-material';
import CompetitionModal from '../components/CompetitionModal';

const placeholderExample = {
  'name': 'KickStart',
  'brief': 'Google Competitions',
  'description': '',
  'url': '',
};

const Header = () => {
  return (
    <Stack spacing={2}>
      <Typography fontStyle="bold" variant="h4" sx={{ marginTop: '1em' }}>
        Competitions & Challenges 🏆
      </Typography>
      <Typography variant="subtitile1">Participate in competitions to explore new fields and make smarter
        friends</Typography>
    </Stack>
  );
};

const Tags = ({ competitionTags, isSelected, setIsSelected }) => {
  const { currentInstitute } = useSelector(store => store);
  return (
    <>
      {
        competitionTags.length == 0 ? null :
          <Box>
            {
              competitionTags.map(tag => {
                console.log(tag);
                console.log(currentInstitute);
                if (tag.institute != null && tag.institute.length !== 0 && !tag.institute.includes(currentInstitute)) return null;
                return (
                  <Chip
                    key={tag._id}
                    clickable
                    variant={isSelected[tag._id] ? 'filled' : 'outlined'}
                    color="info"
                    label={tag.title}
                    onClick={() => {
                      setIsSelected(prev => ({ ...prev, [tag._id]: !prev[tag._id] }));
                    }}
                    sx={{ m: 0.5 }}
                  />
                );
              })
            }
          </Box>
      }
    </>
  );
};

const ListItem = ({ competition, onClick }) => {
  return (
    <ButtonBase onClick={onClick}
      sx={{
        width: '100%',
        justifyContent: 'flex-start',
      }}
    >
      {
        competition.relevant_career_paths.map.length == 0 ? null :
          <>
            <Stack direction="row" flexWrap="nowrap" alignItems="center" width={'100%'} maxWidth={500} sx={{ mx: 1, border: '1px solid #e6e6e6', borderRadius: '10px' }}>
              <Box flexShrink={1} flexGrow={1} sx={{ p: 2 }} justifyContent="flex-start">
                <Typography textAlign="left" flexWrap="wrap" variant="h6">{competition.name}</Typography>
                <Typography textAlign="left" variant="subtitle2">{competition.brief}</Typography>
                <Box flexDirection="row" flexWrap="wrap" sx={{ display: 'flex' }}>
                  {competition.relevant_career_paths.map(career => (
                    (
                      <Chip key={career} size="small" variant="outlined" label={career} sx={{ my: 0.5, mx: 0.5 }} />
                    )
                  ))}
                </Box>
              </Box>
              <Box>
                <IconButton aria-label="website">
                  <Launch />
                </IconButton>
              </Box>
            </Stack>
          </>
      }
    </ButtonBase>
  );
};
const List = ({ isSelected }) => {
  const { competitions, currentInstitute } = useSelector(store => store);
  const selectedCompetitions = useMemo(() => {
    return Object.values(isSelected).every(c => !c) ? competitions :
      competitions.filter((comp) => (comp.tags.reduce((prev, curr) => (prev || isSelected[curr._id]), false)));
  }, [isSelected, competitions]);

  const [selComp, setSelComp] = React.useState(placeholderExample);
  console.log(competitions);
  const [openModal, setOpenModal] = React.useState(false);

  return (
    <>
      {
        selectedCompetitions.length == 0 ? null :
          <>
            <Grid container spacing={2}>
              {selectedCompetitions.map(competition => (
                (
                  <Grid key={competition._id} item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ListItem onClick={() => {
                      setSelComp(competition);
                      setOpenModal(true);
                    }} competition={competition} />
                  </Grid>
                )
              ))}
            </Grid>
            <CompetitionModal insti={currentInstitute.toString().toUpperCase()} data={selComp} open={openModal} handleClose={() => setOpenModal(false)} />
          </>
      }
    </>
  );
};

const CompetitionsPage = () => {
  const dispatch = useDispatch();

  const { competitionTags } = useSelector(store => store);

  useEffect(() => {
    dispatch(initialiseCompetitionTags());
  }, [dispatch]);

  const [isSelected, setIsSelected] = useState({});

  useEffect(() => {
    setIsSelected(competitionTags.map(tag => tag._id).reduce((a, v) => ({ ...a, [v]: false }), {}));
  }, [competitionTags]);

  return (
    <Container fixed>
      <Stack spacing={5} sx={{ marginTop: '4em' }}>
        <Header />
        <Tags competitionTags={competitionTags} isSelected={isSelected} setIsSelected={setIsSelected} />
        <List isSelected={isSelected} />
      </Stack>
    </Container>
  );
};

export default CompetitionsPage;
