import { getAll } from '../service';

const priority = {
  Cell: 1,
  Society: 2,
  'Research Group': 3,
  Club: 4,
  Other: 5,
};

const studentGroupReducer = (state = [], action = {}) => {
  switch (action.type) {
    case 'INIT_STUDENT_GROUPS': {
      return action.data.sort((a, b) => {
        if (priority[a.type] < priority[b.type]) return -1;
        if (priority[a.type] > priority[b.type]) return 1;
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
    }
    default: {
      return state;
    }
  }
};

export const initialiseStudentGroups = (institute) => async (dispatch) => {
  let data = await getAll('student_groups')();
  data = data.filter((group) => group.institute === institute);
  dispatch({
    type: 'INIT_STUDENT_GROUPS',
    data,
  });
};

export default studentGroupReducer;
