import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  maxHeight: '70vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '10px',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': { width: 0 },
};

function CompetitionModal({ insti, data, open, handleClose }) {
  // const [data, setData] = React.useState(competition);
  // React.useEffect(() => {setData(competition);}, [competition]);
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography id="transition-modal-title" variant="h5" component="h2">
            {data.name}
          </Typography>   
          {
            data.description ? (
              <Box sx={{ mt: 3 }}> 
                <Typography id="transition-modal-description" variant="h6">
                  Description
                </Typography>  
                <Typography id="transition-modal-description" sx={{ mt: 0.5 }}>
                  {data.description}
                </Typography>
              </Box>
            ) : null
          }            
          {
            data.organizer ? (
              <Box sx={{ mt: 3 }}> 
                <Typography id="transition-modal-description" variant="h6">
                  Organiser
                </Typography>  
                <Typography id="transition-modal-description" sx={{ mt: 0.5 }}>
                  {data.organizer}
                </Typography>
              </Box>
            ) : null
          }
          {
            data.relevant_career_paths && data.relevant_career_paths.length > 0 ? (
              <Box sx={{ mt: 3 }}> 
                <Typography id="transition-modal-description" variant="h6">
                  Relevant Career Paths
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 0.5 }}>
                  {
                    data.relevant_career_paths.map(c => c).join(', ')
                  }
                </Typography>
              </Box>
            ) : null
          }
          {
            data.past_winners && data.past_winners.length > 0 ? (
              <Box sx={{ mt: 3 }}> 
                <Typography id="transition-modal-description" variant="h6">
                  Past Winners from {insti}
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 0.5 }}>
                  {
                    data.past_winners.map(c => c.name).join(', ')
                  }
                </Typography>
              </Box>
            ) : null
          }
          <Box sx={{
            padding: '0.5em',
            mt: 3,
            textAlign: 'center',
          }}>
            <a href={data.url} style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer">
              <Button 
                variant="contained"
                size="small"
                endIcon={<ArrowForwardIosIcon />}
                sx={{ width: '150px' }}
                fontSize="lg">
                  Visit Page
              </Button>
            </a>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}

export default CompetitionModal;
