import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Logo from '../assets/img/footerDark.png';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

function Footer(props) {
  const { sx } = props;
  const { currentInstitute } = useSelector(store => store);
  const pages = [{ title: 'Study Material', link: '/'.concat(currentInstitute, '/studymaterial') }, { title: 'Beyond Academics', link: '/'.concat(currentInstitute, '/societies') }, { title: 'DepC Fundae', link: '/'.concat(currentInstitute, '/depc') }, { title: 'Insti Fundae', link: '/'.concat(currentInstitute, '/kgpFundae') }, { title: 'Competitions', link: '/'.concat(currentInstitute, '/competitions') }, { title: 'Our Team', link: '/'.concat(currentInstitute, '/ourTeam') }, { title: 'Terms and Conditions', link: '/'.concat(currentInstitute, '/abstract/disclaimer') }];
  return (
    <Box sx={{ height: ['28vh', '20vh'], p: '1.5em', ...sx, width: '100%', display: 'flex', flexDirection: 'row', marginTop: '3em', backgroundColor: 'bg.dark', justifyContent: 'space-between', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', paddingRight: '1em', height: { xs: '50%', sm: '70%', md: '100%' }, justifyContent: 'center', alignContent: 'center', padding: 'auto', flexDirection: ['column', 'row'], alignItems: 'center', textAlign: ['center', 'left']}}>
        <img alt="z Lanchpad Logo" src={Logo} style={{ height: '100%', margin: 'auto', padding: '0.25em' }} />
        <Typography align="center" variant="h6" sx={{ fontSize: ['1rem', '2rem'], fontWeight: '700', color: 'font.light' }}>Launchpad</Typography>
      </Box>
      <Box sx={{ display: 'flex', width: ['60%', '50%'], flexWrap: 'wrap', justifyContent: 'space-evenly', alignItems: 'center' }}>
        {pages.map((page) => (
          <Typography component={Link} to={page.link} py="0.25em" variant="body2" sx={{ margin: '1%', fontSize: ['0.7rem', '0.8rem', '1.0rem'], width: ['46%', '30%'], color: 'font.light', fontWeight: '600', textDecoration: 'none' }} key={page.title}>{page.title}</Typography>
        ))}
        <Typography py="0.25em" variant="body2" sx={{ margin: '1%', fontSize: ['0.7rem', '0.8rem', '1.0rem'], width: ['46%', '30%'], color: 'font.light', fontWeight: '600', textDecoration: 'none' }} onClick={() => window.open('https://wa.me/919711798406')}>Contact Us</Typography>
      </Box>
    </Box>
  );
}

Footer.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object])),
    PropTypes.func,
    PropTypes.object,
  ]),
};

export default Footer;
