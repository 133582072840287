import React from 'react';

import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import theme from '../theme/customTheme';
import { ThemeProvider } from '@mui/material/styles';

import { Link } from 'react-router-dom';
// import { useSelector } from 'react-redux';

const DepCMainCard = () => {
  const [windowWidth, setWidth] = React.useState(window.innerWidth);
  const imageLink = 'https://drive.google.com/uc?id=1layu2A56g2FH5fdF7G04xKE-GEyMVQqz';
  window.addEventListener('resize', () => {
    setWidth(window.innerWidth);
  });
  const pdfLink = useSelector(state => {
    const filtered = state.importantLinks.filter(imp_link => imp_link.name === 'DepC_Criteria');
    if (filtered.length > 0) return filtered[0].link;

    return null;
  });
  return (<ThemeProvider theme={theme}>
    {/* <Box sx={{ display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'center', my:'5%' }}>
      <Box sx={{ display:'flex', flexDirection:(windowWidth>600?'row':'column'), width:(windowWidth>600?'80%':'100%'), height:(windowWidth>600?'40vh':'80vh') }}>
        <Box sx={{ width:(windowWidth>600?'20%':'0%'), height:'10vh' }}></Box>
        <Box sx={{ display:'flex', flexDirection:'column', width:(windowWidth>600?'30%':'100%'), height:(windowWidth<=600?'50%':'100%'), justifyContent:'center' }}>
          <Typography variant="h4" sx={{ fontWeight:'600' }}>DepC Fundae 🧠</Typography>
          <Typography variant="subtitle2" sx={{ color:'#050A3080' }}>Department Change is a thing you&apos;ll hear everyday in your first year of college.<br /><br /></Typography>
          <Typography variant="subtitle2" sx={{ color:'#050A3080' }}>This is the place to be for everything regarding DepC - resources, statistics, criteria and stories!</Typography>
          <Link to="/depc" underline="none" sx={{ textDecoration:'none' }}>
            <Button variant="contained" size="small" endIcon={<ArrowForwardIosIcon />} sx={{ width: '120px' }} fontSize="lg">
              Explore
            </Button>
          </Link>
        </Box>
        <Box sx={{ display:'flex', flexDirection:'column', width:(windowWidth>600?'50%':'100%'), height:(windowWidth<=600?'50%':'100%'), justifyContent:'center', alignItems:'center' }}>
          <Box sx={{ width:'80%', height:'80%', backgroundImage:`url(${imageLink})`, backgroundRepeat:'no-repeat', backgroundSize:'cover' }} onClick={() => window.open(imageLink)}></Box>
        </Box>
      </Box>
      {windowWidth>600?<Box sx={{ display:'flex', flexDirection:'row', justifyContent:'space-evenly', width:'60%' }}>
        <Typography variant="body2" onClick={() => window.open(imageLink)} sx={{ cursor:'pointer', color:'font.subheading' }}>| Previous Year Data</Typography>
        <Typography variant="body2" onClick={() => window.open(pdfLink)} sx={{ cursor:'pointer', color:'font.subheading' }}>| Department Change Criteria</Typography>
        <Link to="/depc" underline="none" sx={{ textDecoration:'none' }}><Typography variant="body2" sx={{ color:'font.subheading', textDecoration:'none' }}>| DepC Stories: the &quot;how&quot;</Typography></Link>
      </Box>:null}
    </Box> */}
    <Box sx={{ display:'flex', justifyContent:'center', flexDirection:'column', alignItems:'center', my:'5%' }}>
      <Box sx = {{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', flexWrap: 'wrap', marginTop: '2em', marginBottom: '2em' }}>
        <Box sx = {{ display: 'flex', flexDirection: 'column', width: ['100%','30%'], padding: '2%', paddingLeft:'3%' }}>
          <Typography fontStyle="bold" variant="h5" sx={{ fontWeight:'700' }}>
          DepC Fundae 🧠
          </Typography>
          <Typography variant="subtitile1" sx = {{ my: '15px' }}>
            Department Change is a thing you&apos;ll hear everyday in your first year of college.<br /><br />
            This is the place to be for everything regarding DepC - resources, statistics, criteria and stories!
          </Typography>
          <Link to="depc" style = {{ textDecoration:'none' }}>
            <Button
              variant="contained"
              size="small"
              endIcon={<ArrowForwardIosIcon />}
              sx={{ width: '120px' }}
              fontSize="lg"  
            >
              Explore
            </Button>
          </Link>
        </Box>
        {
          windowWidth > 599 ? (
            <Box sx = {{ width: '65%', padding: '20px' }} onClick={() => window.open(imageLink)}>
              <img src={imageLink} style={{ width: '100%', cursor:'pointer' }} />
            </Box>
          ):null
        }
      </Box>
      {windowWidth>599 ?<Box sx={{ display:'flex', flexDirection:'row', justifyContent:'space-around', width:'100%' }}>
        <Typography variant="body2" onClick={() => window.open(imageLink)} sx={{ cursor:'pointer', color:'font.subheading' }}>| Previous Year Data</Typography>
        <Typography variant="body2" onClick={() => window.open(pdfLink)} sx={{ cursor:'pointer', color:'font.subheading' }}>| Department Change Criteria</Typography>
        <Link to="depc" underline="none" style={{ textDecoration:'none' }}><Typography variant="body2" sx={{ color:'font.subheading', textDecoration:'none' }}>| DepC Stories: the &quot;how&quot;</Typography></Link>
      </Box>:null}
    </Box>
  </ThemeProvider>);
};

export default DepCMainCard;