import { getAll } from '../service';

const lingoReducer = (state = [], action = {}) => {
  switch (action.type) {
    case 'INIT_LINGO': {
      return action.data;
    }
    case 'CHANGE_LINGO': {
      if (state.length === 0) return state;
      const randomIndex = Math.floor(Math.random() * (state.length - 1));
      const randomElement = state[randomIndex];

      const newState = state.filter((el) => el._id !== randomElement._id);
      return [...newState, randomElement];
    }
    default: {
      return state;
    }
  }
};

export const initialiseLingos = (institute) => async (dispatch) => {
  let data = await getAll('lingo')();
  data = data.filter((lingo) => lingo.institute === institute);
  dispatch({
    type: 'INIT_LINGO',
    data,
  });
};

export const changeLingo = () => async (dispatch) => {
  dispatch({
    type: 'CHANGE_LINGO',
  });
};

export default lingoReducer;
