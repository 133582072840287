import React from 'react';
import { Routes, Route } from 'react-router-dom';
import MainRouter from './MainRouter';
import Home from '../components/Home/Home';
import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';
import { initialiseInstitute } from '../reducers/instituteReducer';
import { useDispatch } from 'react-redux';

function MasterRouter() {

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(initialiseInstitute());
  });

  return (
    <TransitionGroup>
      <CSSTransition
        key={window.location.href}
        timeout={1000}
        classNames="fade"
      >
        <Routes>
          <Route path="/:institute/*" element={<MainRouter />} />          
          <Route path="/" element={<Home />} />
        </Routes>

      </CSSTransition>
    </TransitionGroup>
  );
}

export default MasterRouter;