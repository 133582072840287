import axios from 'axios';

export const backendBase = 'https://yourlaunchpad.live/api';
// export const backendBase = 'https://localhost:3000/api';


export const getAll = (endpoint) => async () => {
  const { data } = await axios.get(`${backendBase}/${endpoint}`);
  return data;
};

const service = {
  getAll,
};

export default service;
