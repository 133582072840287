import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import inspiringKgpianReducer from './reducers/inspiringKgpianReducer';
import studentGroupReducer from './reducers/studentGroupReducer';
import studySubjectReducer from './reducers/studySubjectReducer';
import kgpFundaReducer from './reducers/kgpFundaReducer';
import kgpFundaePorReducer from './reducers/kgpFundaePorReducer';
import competitionReducer from './reducers/competitionReducer';
import competitionTagReducer from './reducers/competitionTagReducer';
import departmentChangeTestimonialReducer from './reducers/departmentChangeTestimonialReducer';
import importantLinkReducer from './reducers/importantLinkReducer';
import lingoReducer from './reducers/lingoReducer';
import SafetyReducer from './reducers/safetyReducer';
import abstractDataReducer from './reducers/abstractDataReducer';
import instituteReducer from './reducers/instituteReducer';
import currentInstituteReducer from './reducers/currentInstituteReducer';

const reducer = combineReducers({
  inspiringKgpians: inspiringKgpianReducer,
  studentGroups: studentGroupReducer,
  studySubjects: studySubjectReducer,
  kgpFundae: kgpFundaReducer,
  kgpFundaePors: kgpFundaePorReducer,
  competitions: competitionReducer,
  competitionTags: competitionTagReducer,
  departmentChangeTestimonials: departmentChangeTestimonialReducer,
  importantLinks: importantLinkReducer,
  lingos: lingoReducer,
  coc: SafetyReducer,
  abstractData: abstractDataReducer,
  institutes: instituteReducer,
  currentInstitute: currentInstituteReducer,
});

const store = createStore(reducer, applyMiddleware(thunk));

export default store;
