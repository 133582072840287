import { React, useEffect } from 'react';
import { Box } from '@mui/material';
import Card from './Components/Card';
import { useSelector, useDispatch } from 'react-redux';
import HomeTopDiv from '../HomeTopDiv';
import { initialiseCurrentInstitute } from '../../reducers/currentInstituteReducer';

export default function Home() {
  const { institutes } = useSelector(state => state);
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(initialiseCurrentInstitute(''));
  }, [dispatch]);


  return (
    <>
      <HomeTopDiv />

      <Box
        sx={{
          // height: '40vh',
          display: 'flex',
          alignItems: 'center',
          // justifyContent: 'space-evenly',
          backgroundColor: '#2D363D',
          overflow: 'scroll',
          '&::-webkit-scrollbar': {
            width: '0 !important',
          },
          padding: '0 1em',
        }}
      >
        {institutes.map((customer, index) => (
          <Box
            sx={{
              padding: '1rem 1rem',
            }}
            key={index} 
          >
            <Card userInfo={customer} />
          </Box>
        ))}
      </Box>

    </>
  );
}
