import React from 'react';
import { Box, Typography, Container, Grid, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

function SafetyAwaressnessHomePageCard(){
  return(
    <Box
      sx={{
        width: '100%',
        height: '10em',
        backgroundImage: 'linear-gradient(to right, #7d0c13, #47060a)',
        borderRadius: '15px',
      }}
    >
      <Container sx={{ height: '100%' }}>
        <Grid container spacing={2} sx={{ height: '100%' }}>
          <Grid item xs={window.innerWidth > 600 ? 4 : 12} sx={{ paddingTop: '0px', textAlign: 'center' }}>
            <Box sx={{ position: 'relative', top: '50%', transform: 'translateY(-50%)' }}>
              <Typography variant="h4" component="div" sx={{ color: 'white', fontWeight: 600, fontSize: 'lg', mb:'4%' }}>
                Safety Awareness
              </Typography>
              <Link to = "safetyAwareness" style={{ textDecoration: 'none' }}>
                <Button
                  variant="contained"
                  size="small"
                  endIcon={<ArrowForwardIosIcon />}
                  sx={{ width: '120px' }}
                >
                Explore
                </Button>
              </Link>
            </Box>
          </Grid>
          {
            window.innerWidth > 600 ? (
              <Grid item xs={8} sx={{ textAlign: 'center' }}>
                <Typography variant="body1" component="div" sx={{ color: 'white', fontSize: 'lg', position: 'relative', top: '50%', transform: 'translateY(-50%)' }}>
                  General Code of Conduct, on campus and beyond!
                </Typography>
              </Grid>
            ) : null
          }          
        </Grid>
        
      </Container>
    </Box>
  );
}

export default SafetyAwaressnessHomePageCard;