import React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

function ImageOverlayCard(props) {
  const { sx, imageLink, overlayColor, ...other } = props;
  return (
    <Box sx={{ backgroundImage: `url(${imageLink})`, backgroundColor: 'primary.dark', backgroundPosition: 'center', backgroundSize: 'cover', ...sx }} {...other}>
      <Box sx={{ display: 'flex', flexDirection: 'row', backgroundColor: overlayColor ? overlayColor : '#00000080', ...sx, width:'100%', height: '100%' }} {...other} />
    </Box>
  );
}

ImageOverlayCard.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object])),
    PropTypes.func,
    PropTypes.object,
  ]),
  imageLink: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object])),
    PropTypes.func,
    PropTypes.object,
  ]),
};

export default ImageOverlayCard;
