/* eslint-disable no-unused-vars */
import React, { useEffect,useState } from 'react';
import { initialiseSafety } from '../reducers/safetyReducer';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Tab, Tabs } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { ThemeProvider } from '@mui/styles';
import theme from '../theme/customTheme';
import remarkGfm from 'remark-gfm';

const SafetyAwarenessPage = () => {
  const dispatch = useDispatch();
  const { coc } = useSelector((state) => state);
  useEffect(() => {
  }, [dispatch]);

  const [tabValue, setTabValue] = useState(0);
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(coc);
  }, [coc]);
  const handleChange = (e, v) =>
  {
    setTabValue(v);
  };
  const showData = () => {
    console.log(data);
  };
  return (
    <ThemeProvider theme = {theme}>
      <Box sx = {{ marginTop: '4em' }}>
        { data[0] !== undefined &&
          <>
            <Box sx = {{ display: 'flex', backgroundColor: '#e0e0e0',justifyContent: 'center', width: '100%' }}>
              <Tabs value = { tabValue } onChange={ handleChange } variant = "scrollable" scrollButtons = "auto" indicatorColor="none">
                <Tab key = {0} label = {data[0].title} disableRipple sx={{ backgroundColor: (tabValue === 0)?'white':'#e0e0e0', fontSize: '12px' }} />
                {data[0].items.map((c,i) => {
                  return <Tab key = {i+1} label = {c.title} disableRipple sx={{ backgroundColor: (tabValue === i+1 ) ?'white':'#e0e0e0', fontSize: '12px' }} />;
                })}
              </Tabs>
            </Box>
            <Box sx = {{ padding: '20px' }}>
              <div key = {0} hidden = {tabValue != 0}>
                <h1>{data[0].title}</h1>
                <ReactMarkdown remarkPlugins={[remarkGfm]}>{data[0].description}</ReactMarkdown>
              </div>
              {data[0].items.map((c,i) => {
                return (
                  <div key = {i+1} hidden = {tabValue != i+1}>
                    <h1>{c.title}</h1>
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>{c.description}</ReactMarkdown>
                    {c.items.map((ci,i) => {
                      return (
                        <>
                          <h3>{ci.title}</h3>
                          <ReactMarkdown remarkPlugins={[remarkGfm]}>{ci.description}</ReactMarkdown>
                        </>        
                      );
                    })}
                  </div>
                );      
              })}
            </Box>
          </>
        }
        
      </Box>
    </ThemeProvider> 
        
  );
};
 
export default SafetyAwarenessPage;