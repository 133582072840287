import { getAll } from '../service';

const competitionTagReducer = (state = [], action = {}) => {
  switch (action.type) {
    case 'INIT_COMPETITION_TAGS': {
      return action.data;
    }
    default: {
      return state;
    }
  }
};

export const initialiseCompetitionTags = () => async (dispatch) => {
  const data = await getAll('competitions/tags')();
  dispatch({
    type: 'INIT_COMPETITION_TAGS',
    data,
  });
};

export default competitionTagReducer;
