import { getAll } from '../service';

const importantLinkReducer = (state = [], action = {}) => {
  switch (action.type) {
    case 'INIT_IMPORTANT_LINKS': {
      return action.data;
    }
    default: {
      return state;
    }
  }
};

export const initialiseImportantLinks = () => async (dispatch) => {
  const data = await getAll('imp_links')();
  dispatch({
    type: 'INIT_IMPORTANT_LINKS',
    data,
  });
};

export default importantLinkReducer;
