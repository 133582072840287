import React from 'react';
import PropTypes from 'prop-types';


import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';

function StudyMaterialListItem(props){
  const { subName, subLink } = props;
  return (
    <ListItem>
      <ListItemIcon sx={{ minWidth:'0' }}>
        <ArrowRightIcon />
      </ListItemIcon>
      <ListItemText
        primary={subName}
        onClick={() => window.open(subLink)}
        style={{ cursor:'pointer' }}
        sx={{ color: 'font.subheading' }}
      />
    </ListItem>
  );
}

StudyMaterialListItem.propTypes = {
  subName: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object])),
    PropTypes.func,
    PropTypes.object,
  ]),
  subLink: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object])),
    PropTypes.func,
    PropTypes.object,
  ]),
};

 
export default StudyMaterialListItem;