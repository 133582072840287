import { getAll } from '../service';

const departmentChangeTestimonialReducer = (state = [], action = {}) => {
  switch (action.type) {
    case 'INIT_DEPARTMENT_CHANGE_TESTIMONIALS': {
      return action.data.sort((a, b) => {
        if (a.year !== b.year) return (b.year - a.year);

        if (a.student.name < b.student.name) return -1;
        if (a.student.name > b.student.name) return 1;
        return 0;
      });
    }
    default: {
      return state;
    }
  }
};

export const initialiseDepartmentChangeTestimonials = (institute) => async (dispatch) => {
  let data = await getAll('/department_change/testimonials')();
  data = data.filter((testimonial) => testimonial.institute === institute);
  dispatch({
    type: 'INIT_DEPARTMENT_CHANGE_TESTIMONIALS',
    data,
  });
};

export default departmentChangeTestimonialReducer;
