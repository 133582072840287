import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Stack, Typography, Container } from '@mui/material';
import { useTheme } from '@emotion/react';

import CustomSlideShow from './CustomSlideshow';

import Default_DP from '../assets/img/default.jpg';

function InspiringKGPiansSlideShow() {
  const { inspiringKgpians, currentInstitute } = useSelector(state => state);

  const InspiringKGPiansCard = ({ data }) => {
    const theme = useTheme();
    let url = '';
    if ('imageUrl' in data) {
      const idx = data.imageUrl.slice(32).slice(0, -17);
      url = 'https://drive.google.com/uc?id=' + idx;
    }

    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'inline-block',
          backgroundImage: `linear-gradient(to right, ${theme.palette.blueGradient.light}, ${theme.palette.blueGradient.dark})`,
          borderRadius: '10px',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            paddingTop: '2em',
          }}
        >
          <Typography component="div" sx={{ color: '#F9F9F9', fontWeight: 600, fontSize: 'lg', textAlign: 'center' }}>
            INSPIRING {currentInstitute.toString().toUpperCase()}IANS💡
          </Typography>
          <Box
            sx={{
              position: 'absolute',
              bottom: '0px',
              width: '100%',
            }}
          >
            <Box
              sx={{
                width: '80%',
                margin: '0 auto',
              }}
            >
              {
                window.innerWidth > 600 ? (
                  <Stack
                    direction="row"
                    sx={{
                      width: '100%',
                      justifyContent: 'space-around',
                      wrap: 'flex-wrap',
                    }}
                  >
                    <Box
                      sx={{
                        width: '10em',
                        height: '10em',
                        minWidth: '200px',
                        backgroundImage: `url(${url})`,
                        backgroundSize: '100% 100%',
                        backgroundRepeat: 'no-repeat',
                        // display: 'inline-block',
                      }}
                    />
                    <Box
                      sx={{
                        // display: 'inline-block',
                        paddingTop: '5vh',
                      }}
                    >
                      <Typography
                        component="div"
                        sx={{
                          color: 'white',
                          fontWeight: 700,
                          fontSize: 'lg',
                        }}
                      >
                        {data.name}
                      </Typography>
                      <Typography
                        component="div"
                        sx={{
                          color: 'white',
                          fontWeight: 500,
                          fontSize: 'md',
                        }}
                      >
                        {data.batch}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        // display: 'inline-block',
                        paddingTop: '5vh',
                      }}
                    >
                      <Typography
                        component="div"
                        sx={{
                          color: 'white',
                          fontWeight: 700,
                          fontSize: 'md',
                        }}
                      >
                        {data.description}
                      </Typography>
                    </Box>
                  </Stack>
                ) : null
              }
              {
                window.innerWidth < 600 ? (
                  <Stack
                    direction="row"
                    sx={{
                      width: '100%',
                      justifyContent: 'space-around',
                      wrap: 'flex-wrap',
                    }}
                  >
                    <Box
                      sx={{
                        width: '10em',
                        height: '10em',
                        minWidth: '150px',
                        backgroundImage: `url(${url == null ? Default_DP : url})`,
                        backgroundSize: '100% 100%',
                        backgroundRepeat: 'no-repeat',
                        // display: 'inline-block',
                      }}
                    />
                    <Box
                      sx={{
                        maxWidth: '200px',
                        overflowWrap: 'break-word',
                      }}
                    >
                      <Typography
                        component="div"
                        sx={{
                          width: '100%',
                          color: 'white',
                          fontWeight: 700,
                          fontSize: 'lg',
                          overflowWrap: 'break-word',
                        }}
                      >
                        {data.name}
                      </Typography>
                      <Typography
                        component="div"
                        sx={{
                          width: '100%',
                          color: 'white',
                          fontWeight: 500,
                          fontSize: 'md',
                          overflowWrap: 'break-word',
                        }}
                      >
                        {data.batch}
                      </Typography>
                      <Typography
                        component="div"
                        sx={{
                          width: '100%',
                          color: 'white',
                          fontWeight: 700,
                          fontSize: 'md',
                          overflowWrap: 'break-word',
                        }}
                      >
                        {data.description}
                      </Typography>
                    </Box>
                  </Stack>
                ) : null
              }
            </Box>
          </Box>
        </Box>

      </Box>
    );
  };



  return (
    <Container
      disableGutters={true}
      sx={{
        overflow: 'hidden',
      }}
    >
      {inspiringKgpians.length > 0 ?
        <CustomSlideShow data={inspiringKgpians} delay={5000}>
          {inspiringKgpians.map((c) => (
            <InspiringKGPiansCard data={c} key={c._id} />
          ))}
        </CustomSlideShow> : null
      }
    </Container>
  );
}

export default InspiringKGPiansSlideShow;