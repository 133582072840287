import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import theme from '../theme/customTheme';
import { ThemeProvider } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { initialiseCompetitionTags } from '../reducers/competitionTagReducer';

const CompetitionsMainCard = () => {

  const dispatch = useDispatch();
  const { competitionTags, currentInstitute } = useSelector(store => store);

  useEffect(() => {
    dispatch(initialiseCompetitionTags());
  }, [dispatch]);

  if (competitionTags.length == 0) // don't show the components at all
    return null;
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'center', flexWrap: 'wrap', marginTop: '3em', marginBottom: '3em' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: '500px', padding: '20px' }}>
          <Typography fontStyle="bold" variant="h5">
            Competitions & Challenges 🏆
          </Typography>
          <Typography variant="subtitile1" sx={{ marginTop: '15px', marginBottom: '15px' }}>
            Participate in competitions to explore new fields and make smarter friends
          </Typography>
          <Link to="competitions" style={{ textDecoration: 'none' }}>
            <Button
              variant="contained"
              size="small"
              endIcon={<ArrowForwardIosIcon />}
              sx={{ width: '120px' }}
              fontSize="lg"
            >
              Explore
            </Button>
          </Link>
        </Box>
        {
          competitionTags.length > 0 ? (
            <Box sx={{ maxWidth: '500px', padding: '20px' }}>
              {competitionTags.map((t, i) => {
                console.log(t);
                if (t.institute != null && t.institute.length !== 0 && !t.institute.includes(currentInstitute)) return null;
                return (
                  <Link to="/competitions" style={{ textDecoration: 'none' }} key={i}>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{ margin: '2px' }}
                    >
                      {t.title}
                    </Button>
                  </Link>
                );
              })}
            </Box>) : null
        }
      </Box>
    </ThemeProvider>

  );
};

export default CompetitionsMainCard;