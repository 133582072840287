import React, { useState } from 'react';
import Button from '@mui/material/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import ImageOverlayCard from '../components/imageOverlayCard';
import theme from '../theme/customTheme';
import background1 from '../assets/img/fundae_bg.jpg';

import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
const KgpFundaeMainCard = () => {
  const { currentInstitute } = useSelector(store => store);
  const [windowWidth, setWidth] = useState(window.innerWidth);
  window.addEventListener('resize', () => {
    setWidth(window.innerWidth);
  });
  return (
    <ThemeProvider theme={theme}>
      <ImageOverlayCard sx={{ minHeight: '30vh', width: '100%' }} imageLink={background1}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          justifyContent: 'center',
          alighItems: 'center',
          margin: 'auto',
          padding: '2%',
          width: '40%',
        }}
        >
          <Typography variant="h3" component="div" sx={{ color: 'white', fontWeight: 700, fontSize: 'lg', mb:'4%' }}>
            {currentInstitute.toString().toUpperCase()} Fundae
          </Typography>
          <Link to = "kgpFundae" style={{ textDecoration: 'none' }}>
            <Button
              variant="contained"
              size="small"
              endIcon={<ArrowForwardIosIcon />}
              sx={{ width: '120px' }}
            >
            Explore
            </Button>
          </Link>
          
        </Box>

        {windowWidth > 500
          ? (
            <Box sx={{
              textAlign: 'center',
              justifyContent: 'center',
              alighItems: 'center',
              padding: '2%',
              margin: 'auto',
            }}
            >
              <Typography component="div" sx={{ color: 'white', fontSize: 'md' }}>
                The Kharagpur campus is huge, and in your 4/5 years at college, you will learn more than your ever
                had.
                <br />
                Find out the best links to start!
              </Typography>
            </Box>
          ) : null}

      </ImageOverlayCard>
    </ThemeProvider>
  );
};

export default KgpFundaeMainCard;