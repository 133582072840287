import React from 'react';
import { Box, Card, CardActionArea, CardMedia, CardContent, Typography, Grid } from '@mui/material';
// import { color } from '@mui/system';

const otherContri = ['Dhawal Barchha','Ronald Sanre','Vivek Saraswat','Sakshi Kumar','Ayan Akthar','Ashok Jangid','Murali Manohar','Yash Vora','Adnan Hassan','Navya Singh','Sakshi Agrawal','Priyansh Seth','Ashutosh Ghuge','Ashish Rekhani','Vikram Sriram','Abdul Nasir','Prithviraj Kadam','Vidisha','Bharat Uday', 'Vignesh Toshniwal', 'Ganji Sanjeev Raj', 'Kalahastri Likhith Sai Ganesh', 'Manosij Sarkar', 'Dheeraj Gahlot', 'Vaibhav Jalani'];
const mainContri = [
  { 'name' : 'Aryansh Singh', 'image':'https://drive.google.com/uc?id=10x9cxfy6pR2gmYLnBs8b6lXQ_k_d0byt', 'social':'https://www.linkedin.com/in/aryansh/' },
  { 'name' : 'Shashvat Gupta', 'image':'https://drive.google.com/uc?id=18oj2zUmJK84RtV3DMidNzn_eV6zNRZ3f', 'social':'https://www.linkedin.com/in/shashvatgupta/' },
  { 'name' : 'Jayanth PSY', 'image':'https://drive.google.com/uc?id=1yeoPB73qoFd5USABC7HExkx80FhCQAmW', 'social':'https://www.linkedin.com/in/j-tesla/' },
  { 'name' : 'Satvik Bansal', 'image':'https://drive.google.com/uc?id=1FlOVwXsjgxrcGYQOVTYNhUMjsKv2qF7i', 'social':'https://www.linkedin.com/in/satvikb27/' },
  { 'name' : 'Karan Uppal', 'image':'https://drive.google.com/uc?id=1Z0-Gp8R0PRptC1SYwf1dg-I59TeW0s4X', 'social':'https://www.linkedin.com/in/karan-uppal3/' },
  { 'name' : 'Sumeet Karwa', 'image':'https://drive.google.com/uc?id=1QUEjzC9BJ3IIycrz79-mRscSP3OQe5IV', 'social':'https://www.linkedin.com/in/sumeet-karwa/' },
  { 'name' : 'Divyajyoti Nayak', 'image':'https://drive.google.com/uc?id=1sikVsAA7VCRU00zZzEx4Mimow4O1x_na', 'social':'https://www.linkedin.com/in/divyajyoti-nayak-3619731a1/' },
  { 'name' : 'Sajal Chhamunya', 'image':'https://drive.google.com/uc?id=1FRppztzKuU6KidAgg9soNht4hIeZ3X8X', 'social':'https://www.linkedin.com/in/sajal-chhamunya/' },
  { 'name' : 'Vaishnav Lembhe', 'image':'https://drive.google.com/uc?id=1iUUUoL779hxAVESUslI9o146rGfisLX4', 'social':'https://www.linkedin.com/in/vaishnav-lembhe-61665a1b4/' },
  { 'name' : 'Akshay Raj Sandiwal', 'image': 'https://drive.google.com/uc?id=1K2IljwUDRAGEnAJrJM7VC1i_A1tDzFMZ', 'social': 'https://www.linkedin.com/in/akshay-raj-sandiwal/' },
  { 'name' : 'Atishay Jain', 'image':'https://drive.google.com/uc?export=view&id=1rzSPq8o50l9_g7Gz6s_wytL7DBs6AGzI', 'social':'https://www.linkedin.com/in/atishay-jain-b3409b233/' },
  { 'name' : 'Monish Natarajan', 'image':'https://drive.google.com/uc?export=view&id=1VDCPVhmyllUujD9fo_GS08lfAnySGChM', 'social':'https://www.linkedin.com/in/monish-natarajan-aa5a13205/' },
  { 'name' : 'Priyanshu Kumar', 'image':'https://drive.google.com/uc?export=view&id=1s1fMNcRGkfLoQDMaUWoL4E1Hrm1OWyle', 'social':'https://www.linkedin.com/in/priyanshu-kumar-6350221ba/' }];

function TeamCard({ name, url, ...other }) {
  return(
    <Card sx={{ width: '100%' }} {...other}>
      <CardActionArea sx={{ height:'100%' }}>
        <CardMedia
          component="img"
          image={url}
          height="300px"
          alt={name}
        // sx={{ height:'50% !important' }}
        />
        <CardContent sx={{ height:'60px' }}>
          <Typography gutterBottom variant="body1" component="div" sx={{ m:'0' }}>
            {name}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

function Team() {
  return(
    <Box sx={{ width: '100%', mt: '15vh' }}>
      <Box>
        <Typography fontStyle="bold" variant="h2" textAlign= "center">
          Our Team
        </Typography>
        <Box sx={{ maxWidth: '1200px', mx: 'auto', px: '5%' }}>
          <Grid container spacing={5} sx={{ my: '20px', p: '2%', justifyContent:'center' }}>
            {
              mainContri.map((student) => (
                <Grid item md={4} xs={12} sm={6} key={student.name}>
                  <TeamCard name={student.name} url={student.image} onClick={() => window.open(student.social)} />
                </Grid>
              ))
            }

          </Grid>
          <Box>
            <Typography fontStyle="bold" variant="h4" textAlign= "center" sx={{ mb: '15px' }}>
              Other Contributors
            </Typography>
            <Box sx={{ display:'flex', flexWrap: 'wrap', direction:'row', justifyContent:'center', alignItems:'center' }}>
              {otherContri.map((name) => (
                <Typography key={name} sx={{ width: ['49%', '29%'], px: '2%', py:'10px', color:'font.subheading' }}>
                  {name}
                </Typography>
              ))}
            </Box>
          </Box>
          <Typography fontWeight="light" variant="h6" py="4em" textAlign="center" fontStyle="italic">Enormous thanks to MetaKGP Wiki, Society heads and Governors.</Typography>
        </Box>
      </Box>

    </Box>
  );
}

export default Team;
