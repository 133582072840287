import React from 'react';
import { Typography, Box } from '@mui/material';
import cardbg from './iit.png';
import { Link } from 'react-router-dom';
import ImageOverlayCard from '../../imageOverlayCard';


const Card = ({ userInfo }) => {
  const { name } = userInfo;
  return (
    <>
      <Link to={userInfo.shortname}>
        {/* <Box sx={{
          backgroundImage: `url(${Object.hasOwn(userInfo, 'photolink') ? 'https://drive.google.com/uc?id=' + userInfo.photolink.slice(32).slice(0, -17) : cardbg})`,
          fontSize: '50px',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          display: 'flex', 
          height: '30vh', 
          padding: '3em 4em',
          // width: '480px',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '15px',
        }}> */}
        <Box
          sx={{
            width: window.innerWidth > 600 ? '50vw' : '15em',
            maxWidth: '45em',
            textDecoration: 'none',
          }}
        >
          <ImageOverlayCard
            imageLink={Object.hasOwn(userInfo, 'photolink') ? 'https://drive.google.com/uc?id=' + userInfo.photolink.slice(32).slice(0, -17) : cardbg}
            overlayColor="#00000080"
            sx={{
              height: window.innerWidth > 600 ? '20em' : '15em',
              width: '100%', 
              alignItems: 'center', 
              justifyContent: 'center',
              borderRadius: '15px',
            }}
          >
            <Typography sx={{ fontSize: 'xl', textDecoration: 'none !important', textAlign: 'center' }} color="white" varient="h1">
              {name}
            </Typography>
          </ImageOverlayCard>
        </Box>


      </Link>
    </>
  );
};

export default Card;