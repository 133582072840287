import React from 'react';
import { AppBar, Box, Button, Container, IconButton, Link, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';



const NavBar = () => {
  const navigate = useNavigate();
  const { studentGroups, studySubjects, kgpFundae, departmentChangeTestimonials, competitions } = useSelector(store => store);
  const { currentInstitute } = useSelector(store => store);
  const link = '/';
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const pages = [
    { title: 'Study Material', link: '/'.concat(currentInstitute, '/studymaterial'), dataList: studySubjects },
    { title: 'Beyond Academics', link: '/'.concat(currentInstitute, '/societies'), dataList: studentGroups },
    { title: 'DepC Fundae', link: '/'.concat(currentInstitute, '/depc'), dataList: departmentChangeTestimonials },
    { title: 'Insti Fundae', link: '/'.concat(currentInstitute, '/kgpFundae'), dataList: kgpFundae },
    { title: 'Competitions', link: '/'.concat(currentInstitute, '/competitions'), dataList: competitions },
    { title: 'Our Team', link: '/'.concat(currentInstitute, '/ourTeam') },
    { title: 'Terms and Conditions', link: '/'.concat(currentInstitute, '/abstract/disclaimer') }];
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar sx={{ bgcolor: 'font.darker' }} position="fixed" shadow={0}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link
            underline="none"
            to={link}
            variant="h6"
            noWrap
            component={RouterLink}
            sx={{ mr: 2, flexGrow: 1, color: 'font.light', fontWeight: '700', display: { xs: 'none', md: 'flex' }}}
          >
            {currentInstitute.toString().toUpperCase()} Launchpad
          </Link>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }}}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ color: 'font.light' }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.title} onClick={() => { handleCloseNavMenu(); navigate(page.link); }}>
                  <Typography textAlign="center" color={window.innerWidth > 1000 ? 'font.light' : 'font.dark'}>{page.title}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Link
            underline="none"
            to="/"
            variant="h6"
            noWrap
            component={RouterLink}
            sx={{ flexGrow: 1, color: 'font.light', fontWeight: '800', display: { xs: 'flex', md: 'none' }}}
          >
            {currentInstitute.toString().toUpperCase()} Launchpad
          </Link>
          <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' }}}>
            {pages.map((page) => {
              if ('dataList' in page) {
                if (page.dataList != null && page.dataList.length > 0) {
                  return (
                    <Button
                      key={page.title}
                      sx={{ color: 'font.light', fontWeight: '600', ml: 1.5 }}
                      onClick={() => { handleCloseNavMenu(); navigate(page.link); }}
                    >
                      {page.title}
                    </Button>
                  );
                }
                else return null;
              }
              else {
                return (
                  <Button
                    key={page.title}
                    sx={{ color: 'font.light', fontWeight: '600', ml: 1.5 }}
                    onClick={() => { handleCloseNavMenu(); navigate(page.link); }}
                  >
                    {page.title}
                  </Button>
                );
              }
            })}
          </Box>

        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavBar;
