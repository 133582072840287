import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../theme/customTheme';
import ImageOverlayCard from '../components/imageOverlayCard';
import background from '../assets/img/society_bg.jpg';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';

const SocietyMainCard = () => {
  const { currentInstitute } = useSelector(store => store);
  const [windowWidth, setWidth] = useState(window.innerWidth);
  window.addEventListener('resize', () => {
    setWidth(window.innerWidth);
  });

  return (
    <ThemeProvider theme={theme}>
      <ImageOverlayCard
        sx={{
          height: '300px', width: '100%', alignItems: 'center', justifyContent: 'center',
        }}
        imageLink={background}
      >   
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          width: '60%',
        }}
        >
          <Typography variant="h3" component="div" sx={{ color: 'white', fontWeight: 700, fontSize: 'md', mb:'2%' }}>
            {/* Everything about */}
            {/* {' '} */}
            {/* <strong>Societies</strong> */}
            {/* {' '} */}
            <strong>Beyond Academics</strong>
          &#x1F30F;
          </Typography>

          {windowWidth > 500
            ? (
              <Typography component="div" sx={{ color: 'white', fontSize: 'md', mb:'2%' }}>
                {/* Explore the societies and clubs of IIT Kharagpur, find everything you need to be a part of them -
              information, resources, socials! */}
                From sports to tech to social and cultural activities, {currentInstitute.toString().toUpperCase()}ians have excelled in all, withstanding the rigorous academic schedule, find everything you need to be a part of them - information, resources, socials!
              </Typography>
            ) : null}
          <Link to="societies" underline="none" style={{ textDecoration:'none', mt:'2%' }}>
            <Button
              variant="contained"
              size="small"
              endIcon={<ArrowForwardIosIcon />}
              sx={{ width: '120px', mt:'2%' }}
              fontSize="lg"
            >
              Explore
            </Button>
          </Link>
        </Box>
      </ImageOverlayCard>
    </ThemeProvider>
  );
};

export default SocietyMainCard;
