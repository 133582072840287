
const currentInstituteReducer = (state = [], action = {}) => {
  switch (action.type) {
    case 'INIT_CURR_INSTITUTE': {
      return action.data;
    }
    default: {
      return state;
    }
  }
};

export const initialiseCurrentInstitute = (data) => async (dispatch) => {
  dispatch({
    type: 'INIT_CURR_INSTITUTE',
    data,
  });
};


export default currentInstituteReducer;
