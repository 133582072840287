import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';

function StudyMaterialPageItem(props){
  const { sx, subName, subLink } = props;
  return (
    <Box sx={{ display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', mx:'2%', backgroundColor:'white', backgroundSize:'cover', ...sx, flexShrink:'0', cursor:'pointer' }} onClick={() => window.open(subLink)}>
      <Box sx={{ display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', textAlign:'center', backgroundColor:'hsla(196, 64%, 69%, 0.3)', backgroundSize:'cover', width:'100%', height:'100%', flexShrink:'0', padding:'10%', ':hover': { boxShadow: 6, backgroundColor:'hsla(196, 64%, 69%)' }}}>
        <Typography variant="body1" align="center" sx={{ margin:'0 auto', width:'100%', fontSize:['0.7rem', '1rem']}}>{subName}</Typography>
      </Box>
    </Box>
  );
}

StudyMaterialPageItem.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object])),
    PropTypes.func,
    PropTypes.object,
  ]),
  subName: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object])),
    PropTypes.func,
    PropTypes.object,
  ]),
  subLink: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object])),
    PropTypes.func,
    PropTypes.object,
  ]),
};

 
export default StudyMaterialPageItem;
