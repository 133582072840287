import { getAll } from '../service';

const competitionReducer = (state = [], action = {}) => {
  switch (action.type) {
    case 'INIT_COMPETITIONS': {
      return action.data.sort((a, b) => {
        if (a.priority_rank && b.priority_rank) return a.priority_rank - b.priority_rank;
        if (a.priority_rank) return -1;
        if (b.priority_rank) return 1;
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
    }
    default: {
      return state;
    }
  }
};

export const initialiseCompetitions = (institute) => async (dispatch) => {
  let data = await getAll('competitions')();
  data = data.filter((competition) => competition.institute === institute);
  dispatch({
    type: 'INIT_COMPETITIONS',
    data,
  });
};

export default competitionReducer;
