import React, { useEffect, useState } from 'react';
import { initialiseKgpFundae } from '../reducers/kgpFundaReducer';
import { useDispatch, useSelector } from 'react-redux';
import theme from '../theme/customTheme';
import { ThemeProvider } from '@mui/material/styles';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import background1 from '../assets/img/fundae_bg.jpg';
import ImageOverlayCard from '../components/imageOverlayCard';
import remarkGfm from 'remark-gfm';



const KgpFundaePage = () => {
  const dispatch = useDispatch();
  const { currentInstitute, kgpFundae } = useSelector((state) => state);
  useEffect(() => {
    dispatch(initialiseKgpFundae(currentInstitute));
  }, [dispatch, currentInstitute]);

  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <ThemeProvider theme={theme}>
      <Box className="kgpf-container" fontFamily="typography.fontFamily" sx={{ marginTop: '4em' }}>
        {/* insert navbar */}
        <Box className="kgpf-header">
          <ImageOverlayCard sx={{ height: '20vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} imageLink={background1}>
            <Box color="font.light">
              <h1>{currentInstitute.toString().toUpperCase()} Fundae</h1>
            </Box>
          </ImageOverlayCard>

        </Box>
        {
          kgpFundae.length == 0 ? null :
            <Box className="kgpf-body">
              <Box className="kgpf-tabs" sx={{ display: 'flex', justifyContent: 'center', backgroundColor: '#e0e0e0' }}>
                <Tabs value={tabValue} onChange={handleChange} variant="scrollable" scrollButtons="auto" indicatorColor="white">
                  {kgpFundae.map((f, i) => {
                    return <Tab key={i} label={f.title} disableRipple sx={{ backgroundColor: (i === tabValue) ? 'white' : '#e0e0e0', fontSize: '12px' }} />;
                  })}
                </Tabs>
              </Box>
              <Box className="kgpf-content" sx={{ paddingLeft: '5%', paddingRight: '5%', paddingTop: '2%' }}>
                {kgpFundae.map((f, i) => {
                  return (
                    <div key={i} hidden={tabValue != i}>
                      {tabValue === i && (f.items.map((fi, i) => {
                        return (
                          <div key={i}>
                            <h2>{fi.title}</h2>
                            <ReactMarkdown remarkPlugins={[remarkGfm]}>{fi.description}</ReactMarkdown>
                            {fi.items.map((fii, i) => {
                              return (
                                <div key={i}>
                                  <h4>{fii.title}</h4>
                                  <ReactMarkdown remarkPlugins={[remarkGfm]}>{fii.description}</ReactMarkdown>
                                </div>
                              );
                            })}
                            {fi.source && (<Typography textAlign="right">Source: {fi.source}</Typography>)}
                          </div>
                        );
                      }))}
                    </div>
                  );
                })}
              </Box>
            </Box>
        }
      </Box>
    </ThemeProvider>
  );
};

export default KgpFundaePage;
