import React from 'react';
import Button from '@mui/material/Button';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../theme/customTheme';
import StudyMaterialListItem from '../components/StudyMaterialListItem';
import background2 from '../assets/img/study_material_bg.png';
import List from '@mui/material/List';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';


const StudyMaterialHome = () => {
  const { studySubjects } = useSelector((state => state));
  // const [windowWidth, setWidth] = useState(window.innerWidth);
  // window.addEventListener('resize', () => {
  //   setWidth(window.innerWidth);
  // });

  return (<ThemeProvider theme={theme}>
    <Box sx={{ display:'flex', flexDirection:['column', 'row'], width:'100%' }}>
      <Box sx={{ display:'flex', flexdirection:'column', width:['100%', '50%'], height:['50vh', '100vh'], backgroundImage: `url(${background2})`, backgroundPosition:'center', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%', alignItems:'right !important', justifyContent:['center', 'right']}}>
        <Box sx={{ display:'flex', flexDirection:'column', width: [ '80%', '40%'], height:['50%','30%'], justifyContent:'space-between',margin:'auto 0' }}>
          <Typography variant="h5" sx={{ fontWeight:'700' }}>Study Material &#128218;</Typography>
          <Typography variant="body2" component="span" sx={{ margin:'0 auto' }}>Link to all the{' '}<Typography variant="body2" component="span" sx={{ color:'font.subheading' }}>assignments, theory, reference books, notes, class notes, previous year question papers, lab report</Typography>{', '} and a lot more.</Typography>
          <Link to={'studymaterial'} underline="none" style={{ textDecoration:'none' }}>
            <Button
              variant="contained"
              size="small"
              endIcon={<ArrowForwardIosIcon />}
              sx={{ width: '120px' }}
              fontSize="lg"
            >
              Explore
            </Button>
          </Link>
        </Box>
      </Box> 
      <Box sx={{ display:'flex', flexdirection:'column', width:['100%', '50%'], height:'auto', backgroundPosition:'center', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%', alignItems:'right', justifyContent:'space-around' }}>
        <Box sx={{ display:'flex', flexDirection:'column', height:'100%', width:'50%', margin:'0 auto', paddingLeft:'1%' }}>
          <Box sx={{ display:'flex', flexDirection:'column', margin:'30% auto' }}>
            {
              studySubjects.length == 0 ? null:
                <>
                  <Typography variant="h5" sx={{ fontWeight:'700' }}>PHYSICS SEM</Typography>
                  <List>
                    {studySubjects.map(subject => (
                      (subject.semester=='physics'?<StudyMaterialListItem subName={subject.name} key={subject.name} subLink={subject.url} />:null)

                    ))}
                  </List>
                </>
            }
          </Box>
        </Box>
        <Box sx={{ display:'flex', flexDirection:'column', height:'100%', width:'50%', paddingLeft:'1%' }}>
          <Box sx={{ display:'flex', flexDirection:'column', margin:'30% 0' }}>
            {
              studySubjects.length == 0 ? null:
                <>
                  <Typography variant="h5" sx={{ fontWeight:'700' }}>CHEMISTRY SEM</Typography>
                  <List>
                    {studySubjects.map(subject => (
                      (subject.semester=='chemistry'?<StudyMaterialListItem subName={subject.name} key={subject._id} subLink={subject.url} />:null)
                        
                    ))}
                  </List>
                </>
            }
          </Box>
        </Box>
      </Box> 
    </Box>
  </ThemeProvider>);
};

export default StudyMaterialHome;