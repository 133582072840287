import { getAll } from '../service';

const instituteReducer = (state = [], action = {}) => {
  switch (action.type) {
    case 'INIT_INSTITUTE': {
      return action.data;
    }
    default: {
      return state;
    }
  }
};

export const initialiseInstitute = () => async (dispatch) => {
  const data = await getAll('institute')();
  dispatch({
    type: 'INIT_INSTITUTE',
    data,
  });
};

export default instituteReducer;
