import React from 'react';
import {
  Box,
  Grid,
  Typography,
  Container,
  Link,
  Stack,
} from '@mui/material';
import TopDiv_bg from '../assets/img/TopDiv_bg.png';
import TopDiv_android from '../assets/img/TopDiv_android2.png';
import TopDiv_btn from '../assets/img/TopDiv_btn.png';
import TopDiv_under from '../assets/img/TopDiv_under.png';

function HomeTopDiv() {

  const url = `${TopDiv_bg}`;

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100vh',
          backgroundImage: `url(${url})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            backgroundImage: `linear-gradient(
                rgba(5, 10, 48, 1), 
                rgba(5, 10, 48, 0.98),
                rgba(5, 10, 48, 0.96),
                rgba(5, 10, 48, 0.94),
                rgba(5, 10, 48, 0.92),
                rgba(5, 10, 48, 0.9),  
                rgba(5, 10, 48, 0.85),  
                rgba(5, 10, 48, 0.8), 
                rgba(5, 10, 48, 0.75), 
                rgba(5, 10, 48, 0.7),  
                rgba(5, 10, 48, 0.65),  
                rgba(5, 10, 48, 0.6),
                rgba(5, 10, 48, 0.55),
                rgba(5, 10, 48, 0.5), 
                rgba(255, 255, 255, 0)
              )`,
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '80%',
              position: 'absolute',
              bottom: '0px',
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                position: 'absolute',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: '50%',
                  backgroundImage: `url(${TopDiv_under})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  position: 'absolute',
                  bottom: '0px',
                }}
              />
            </Box>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                position: 'absolute',
              }}
            >
              <Container sx={{ height: '100%' }}>
                <Grid container spacing={2} height="100%">
                  <Grid item xs={window.innerWidth > 600 ? 8 : 12}>
                    <Box
                      sx={{
                        width: '100%',
                        position: 'relative',
                        top: '50%',
                        transform: 'translateY(-50%)',
                      }}
                    >
                      <Box sx={{
                        padding: '0.5em',
                      }}>
                        <Typography variant="h3" component="div" sx={{
                          margin: '0 auto',
                          color: 'white',
                          fontWeight: 600,

                        }}>
                          Your
                        </Typography>
                        <Typography variant="h3" component="div" sx={{
                          margin: '0 auto',
                          color: 'white',
                          fontWeight: 600,

                        }}>
                          Launchpad
                        </Typography>
                      </Box>

                      <Box px="0.5em" py="1.5em">
                        <Typography variant="body1" component="div" sx={{
                          margin: '0 auto',
                          color: 'white',
                        }}>
                          Welcome to your Launchpad! <br />
                          Fall in love with your institute&apos;s culture & explore everything you need in the first year.
                        </Typography>
                      </Box>

                      <Box py="2em">
                        <Typography textAlign="center" sx={{ color: 'grey.main', fontStyle: 'italic' }}>
                          Made with ❤️
                        </Typography>

                        <Typography textAlign="center" sx={{ color: 'grey.main', fontStyle: 'italic' }}>
                          by KGPians
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  {
                    window.innerWidth > 600 ?
                      <Grid item xs={4}>
                        <Stack
                          direction="row" spacing={2}
                          alignItems="flex-end"
                          sx={{
                            width: '100%',
                            position: 'relative',
                            top: '50%',
                            transform: 'translateY(-50%)',
                          }}
                        >
                          <Link href="https://play.google.com/store/apps/details?id=com.kgplaunchpad" sx={{ display: 'inline-block' }}>
                            <img src={TopDiv_btn} alt="Link to Play Store" />
                          </Link>
                          <img src={TopDiv_android} alt="Android" />
                        </Stack>
                      </Grid> : null
                  }
                </Grid>

              </Container>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default HomeTopDiv;
