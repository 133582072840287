import { getAll } from '../service';

const inspiringKgpianReducer = (state = [], action = {}) => {
  switch (action.type) {
    case 'INIT_INSPIRING_KGPIANS': {
      return action.data;
    }
    default: {
      return state;
    }
  }
};

export const initialiseInspiringKgpians = (institute) => async (dispatch) => {
  let data = await getAll('inspiring_kgpians')();
  data = data.filter((kgpian) => kgpian.institute === institute);
  dispatch({
    type: 'INIT_INSPIRING_KGPIANS',
    data,
  });
};

export default inspiringKgpianReducer;
