import React from 'react';
import Box from '@mui/material/Box';

function CustomSlideShow({ data, children, delay, ...props }){
  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  function forwardSlide(){
    setIndex((prevIndex) =>
      prevIndex === data.length - 1 ? 0 : prevIndex + 1,
    );
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === data.length - 1 ? 0 : prevIndex + 1,
        ),
      delay,
    );

    return () => {
      resetTimeout();
    };
  }, [index, data.length, delay]);

  return (
    <>
      <Box
        sx={{
          whiteSpace: 'nowrap',
          transition: 'ease 1000ms',
          width: '100%',
          height: props.height ? props.height : '15em',
          transform: `translate3d(${-index * 100}%, 0, 0)`,
          cursor: 'pointer',
        }}
        onClick={forwardSlide}
      >
        {children}
      </Box>
    </>
  );
}

export default CustomSlideShow;