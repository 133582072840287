import React, { useEffect } from 'react';
import { Routes, Route, useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// import Home from '../components/Home/Home';
import HomePage from '../pages/HomePage';
import SocietyListPage from '../pages/SocietyListPage';
import CompetitionsPage from '../pages/CompetitionsPage';
import KgpFundaePage from '../pages/KgpFundaePage';
import StudyMaterialPage from '../pages/StudyMaterialPage';
import DepcPage from '../pages/DepcPage';
import Team from '../pages/OurTeam';
import SafetyAwarenessPage from '../pages/SafetyAwarenessPage';
import AbstractDataPage from '../pages/AbstractDataPage';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';


import { initialiseStudySubjects } from '../reducers/studySubjectReducer';
import { initialiseInspiringKgpians } from '../reducers/inspiringKgpianReducer';
import { initialiseLingos } from '../reducers/lingoReducer';
import { initialiseSafety } from '../reducers/safetyReducer';
import { initialiseStudentGroups } from '../reducers/studentGroupReducer';
import { initialiseDepartmentChangeTestimonials } from '../reducers/departmentChangeTestimonialReducer';
import { initialiseImportantLinks } from '../reducers/importantLinkReducer';
import { initialiseAbstractData } from '../reducers/abstractDataReducer';
// import { initialiseInstitute } from '../reducers/instituteReducer';
import { initialiseCurrentInstitute } from '../reducers/currentInstituteReducer';
import { initialiseCompetitions } from '../reducers/competitionReducer';
import { initialiseKgpFundae } from '../reducers/kgpFundaReducer';

function MainRouter() {
  const location = useLocation();
  const { institute } = useParams();

  const dispatch = useDispatch();

  const effect = () => {
    dispatch(initialiseCurrentInstitute(institute));
    dispatch(initialiseStudySubjects(institute));
    dispatch(initialiseInspiringKgpians(institute));
    dispatch(initialiseLingos(institute));
    dispatch(initialiseSafety(institute));
    dispatch(initialiseStudentGroups(institute));
    dispatch(initialiseDepartmentChangeTestimonials(institute));
    dispatch(initialiseImportantLinks());
    dispatch(initialiseAbstractData());
    dispatch(initialiseCompetitions(institute));
    dispatch(initialiseKgpFundae(institute));
  };
  useEffect(effect, [dispatch, institute]);


  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <NavBar />
      <Routes>
        <Route path="/depc" element={<DepcPage />} />
        <Route path="/studymaterial" element={<StudyMaterialPage />} />
        <Route path="/competitions" element={<CompetitionsPage />} />
        <Route path="/societies" element={<SocietyListPage />} />
        <Route path="/kgpFundae" element={<KgpFundaePage />} />
        <Route path="/ourTeam" element={<Team />} />
        <Route path="/safetyAwareness" element={<SafetyAwarenessPage />} />
        <Route path="/abstract/:name" element={<AbstractDataPage />} />
        <Route path="/" element={<HomePage />} />
      </Routes>
      <Footer />
    </>
  );
}

export default MainRouter;
