import React from 'react';
// import Image from 'material-ui-image';
import { Box, Typography } from '@mui/material';

import theme from '../theme/customTheme';
import { ThemeProvider } from '@mui/material/styles';

import { useSelector } from 'react-redux';
import DepcPageCard from '../components/DepcPageCard';
const Depc = () => {
  const { departmentChangeTestimonials } = useSelector((state => state));
  const imageLink = 'https://drive.google.com/uc?id=1layu2A56g2FH5fdF7G04xKE-GEyMVQqz';
  return (<ThemeProvider theme={theme}>
    <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', mt: '4em' }}>
      <Box sx={{ display: 'flex', flexDirection: ['column', 'row'], width: ['100%', '80%'], height: 'auto', justifyContent: 'center', alignItems: 'center', py: '5vh' }}>
        {/* <Box sx={{ width:['0%', '20%'], height:['0vh', '10vh']}}></Box> */}
        <Box sx={{ display: 'flex', flexDirection: 'column', width: ['90%', '30%'], height: 'auto', justifyContent: 'center' }}>
          <Typography variant="h4" sx={{ fontWeight: '600' }}>DepC Fundae 🧠</Typography>
          <Typography variant="subtitle2" sx={{ color: '#050A3080' }}>Department Change is a thing you&apos;ll hear everyday in your first year of college.<br /><br /></Typography>
          <Typography variant="subtitle2" sx={{ color: '#050A3080' }}>This is the place to be for everything regarding DepC - resources, statistics, criteria and stories!</Typography>
        </Box>
        {/* <Box sx={{ display:'flex', flexDirection:'column', width:['80%', '50%'], justifyContent:'center', alignItems:'center' }}> */}
        {/* <Image src="url(${imageLink})" /> */}
        {/* <Box sx={{ width:'80%', height:'80%', position: 'relative', top: '50%', transform: 'translateY(-50%)', backgroundImage:`url(${imageLink})`, backgroundRepeat:'no-repeat', backgroundSize:'contain' }} onClick={() => window.open(imageLink)}></Box> */}
        <Box sx={{ width: ['100%', '65%'], p: '5%' }} onClick={() => window.open(imageLink)}>
          <img src={imageLink} style={{ width: '100%' }} />
        </Box>
      </Box>
      {
        departmentChangeTestimonials.length == 0 ? null :
          <Box sx={{ width: '90%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly' }}>
            {
              departmentChangeTestimonials.map(student => (
                <DepcPageCard student={student} key={student._id} sx={{ width: ['100%', '45%', '23%'], height: ['80vh', '90vh'], m: '1%', minWidth: '200px' }}></DepcPageCard>
              ))
            }
          </Box>
      }
    </Box>
  </ThemeProvider>);

};

function DepcPage() {
  return (
    <Depc />
  );
}

export default DepcPage;
