import CssBaseline from '@mui/material/CssBaseline';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/customTheme';

import MasterRouter from './routers/MasterRouter';



const App = () => {
  return (
    <ThemeProvider theme={ theme }>
      <CssBaseline />
      <MasterRouter />
    </ThemeProvider>
  );
};
export default App;
