import { getAll } from '../service';

const studySubjectReducer = (state = [], action = {}) => {
  switch (action.type) {
    case 'INIT_STUDY_SUBJECTS': {
      return action.data;
    }
    default: {
      return state;
    }
  }
};


export const initialiseStudySubjects = (institute) => async (dispatch) => {
  let data = await getAll('study/subjects')();
  data = data.filter((subject) => subject.institute === institute);
  dispatch({
    type: 'INIT_STUDY_SUBJECTS',
    data,
  });
};

export default studySubjectReducer;
