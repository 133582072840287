import { Typography, Box } from '@mui/material';
import React from 'react';
import ImageOverlayCard from './imageOverlayCard';
import PropTypes from 'prop-types';

function SocietyPageCard(props){
  const { sx, logoLink, backgroundLink, name, ...other } = props;
  let imgbgurl = null;
  if (backgroundLink){
    const imgbgidx = props.backgroundLink.slice(32).slice(0, -17);
    imgbgurl = 'https://drive.google.com/uc?id=' + imgbgidx;
  }
  let logourl = null;
  if (logoLink){
    const logoidx = props.logoLink.slice(32).slice(0, -17);
    logourl = 'https://drive.google.com/uc?id=' + logoidx;
  }
  return (
    <ImageOverlayCard sx={{ height: '6vh', width: '100%', maxWidth: '20em', minWidth: '200px', alignItems: 'center', justifyContent: 'center', borderRadius: '5px', overflow: 'hidden', cursor: 'pointer', alignContent: 'center', ...sx }} imageLink = {imgbgurl} overlayColor={'rgba(37, 48, 67, 0.5)'} {...other}>
      <Box sx={{ display: 'flex', width:'2em', height:'2em', marginLeft:'1vw', alignItems: 'center', justifyContent: 'center' }}>
        <img src={logourl} style={{ display:'flex', height:'80%', width:'80%', borderRadius:'50%', margin:'auto', verticalAlign:'center' }} />
      </Box>
      <Typography variant="body2" sx={{ display:'flex', color: 'font.light', fontWeight: 600, marginLeft: '1vw', ...sx,  width: '80%', height:'auto' }}>
        {name}
      </Typography>
    </ImageOverlayCard>
  );
}
SocietyPageCard.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object])),
    PropTypes.func,
    PropTypes.object,
  ]),
  logoLink: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object])),
    PropTypes.func,
    PropTypes.object,
  ]),
  backgroundLink: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object])),
    PropTypes.func,
    PropTypes.object,
  ]),
  name: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object])),
    PropTypes.func,
    PropTypes.object,
  ]),
};
export default SocietyPageCard;
