import sortBy from 'lodash/sortBy';

import { getAll } from '../service';

const kgpFundaReducer = (state = [], action = {}) => {
  switch (action.type) {
    case 'INIT_KGP_FUNDAE': {
      // eslint-disable-next-line no-restricted-syntax
      for (const funda of action.data) {
        if (funda.items) funda.items = sortBy(funda.items, 'title');
      }
      return action.data.sort((a, b) => {
        if (a.priority_rank && b.priority_rank) return a.priority_rank - b.priority_rank;
        if (a.priority_rank) return -1;
        if (b.priority_rank) return 1;
        return 0;
      });
    }
    default: {
      return state;
    }
  }
};
export const initialiseKgpFundae = (institute) => async (dispatch) => {
  let data = await getAll('kgp_fundae')();
  data = data.filter((funda) => funda.institute === institute);
  dispatch({
    type: 'INIT_KGP_FUNDAE',
    data,
  });
};

export default kgpFundaReducer;
