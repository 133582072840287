import React from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import CustomSlideShow from './CustomSlideshow';
import { useTheme } from '@emotion/react';

function KGPLingo() {
  const theme = useTheme();
  const { currentInstitute } = useSelector(store => store);

  const LingoCard = ({ data }) => {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'inline-block',
          backgroundImage: `linear-gradient(to right, ${theme.palette.blueGradient.dark}, ${theme.palette.blueGradient.light})`,
          borderRadius: '10px',
        }}
      >
        <Box
          sx={{
            padding: '3em',
            textAlign: 'center',
          }}
        >
          <Typography
            sx={{
              color: 'rgba(249, 249, 249, 0.69)',
              fontWeight: 600,
              fontSize: 'xl',
            }}
          >
            The {currentInstitute.toString().toUpperCase()} Lingo
          </Typography>
          <Typography
            sx={{
              color: 'white',
              fontWeight: 700,
              fontSize: 'xl',
            }}
          >
            {data.word}
          </Typography>
          <Typography
            sx={{
              color: 'white',
              fontWeight: 500,
              fontSize: 'xl',
              // wordWrap: 'break-word',
            }}
            style={{ wordWrap: 'break-word' }}
          >
            {data.meaning}
          </Typography>
        </Box>
      </Box>
    );
  };

  const { lingos } = useSelector((state => state));
  return (
    <Box
      sx={{
        overflow: 'hidden',
        width: '100%',
      }}
    >
      {
        lingos.length > 0 ?
          <CustomSlideShow delay={15000} data={lingos} height="10em">
            {lingos.map((c) => (
              <LingoCard data={c} key={c._id} />
            ))}
          </CustomSlideShow> : null
      }
    </Box>
  );
}

export default KGPLingo;