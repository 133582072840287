import background from '../assets/img/society_bg.jpg';

import React, { useState } from 'react';
import ImageOverlayCard from '../components/imageOverlayCard';
import {
  Box, 
  Typography,
  Container,
  Stack,
  Grid,
} from '@mui/material';


import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import LanguageIcon from '@mui/icons-material/Language';
import InstagramIcon from '@mui/icons-material/Instagram';
import IconButton from '@mui/material/IconButton';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';


import SocietyPageCard from '../components/SocietyPageCard';
import { useSelector } from 'react-redux';

import theme from '../theme/customTheme';
import { ThemeProvider } from '@mui/material/styles';

const StudentPage = () => {

  const { studentGroups, currentInstitute } = useSelector((state => state));
  const [societyObject, setSociety] = useState(null);
  React.useEffect(() => {
    if (studentGroups) setSociety(studentGroups[0]);
  }, [studentGroups]);

  const styleList = {
    '&::-webkit-scrollbar': { width: 0 },
  };
  
  return (
    <>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center', marginTop: '4em', backgroundColor: '#ECF0F5' }}>
          <ImageOverlayCard sx={{ height: '30vh', width: '100%', alignItems: 'center', justifyContent: 'center', alignContent: 'center' }} overlayColor={'rgba(37, 48, 67, 0.5)'} imageLink = {background}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', textAlign:'left', width: '80%' }}>
              <Typography variant="h1" component="div" sx={{ color: 'white', fontWeight: 700, fontSize:'6vh !important' }}>
                {/* Everything about <strong>Societies</strong> &#x1F30F; */}
                <strong>Beyond Academics</strong>
              </Typography>
              {
                window.innerWidth > 600 ? (
                  <Box>
                    <Typography variant="body1" sx={{ color: 'white', fontWeight: 500 }}>
                      {/* Explore the societies and clubs of IIT Kharagpur, find everything you need to be a part of them - information, resources, socials! */}
                      From sports to tech to social and cultural activities, {currentInstitute.toUpperCase()}ians have excelled in all, notwithstanding the rigorous academic schedule, find everything you need to be a part of them - information, resources, socials!
                    </Typography>
                  </Box>
                ):null
              }
            </Box>
          </ImageOverlayCard>
        </Box>
        <Container maxWidth="lg" sx={{ marginTop: '3em' }}>
          {
            window.innerWidth > 600 ? (
              <Grid container spacing={5}>
                <Grid item xs={3}>
                  <Stack sx={{ maxHeight: '100vh', overflowY: 'scroll', ...styleList }} spacing={2}>
                    {
                      studentGroups.map(society => (
                        <Box key={society.name} onClick = {() => setSociety(society)}>
                          <SocietyPageCard name={society.name} backgroundLink={society.bg} logoLink={society.logo} sx={{ height: '10vh' }} />
                        </Box>
                      ))
                    }
                  </Stack>
                </Grid>
                <Grid item xs={9}>
                  {
                    societyObject ? (
                      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '2em' }}>
                        <SocietyPageCard name={societyObject.name} backgroundLink={societyObject.bg} logoLink={societyObject.logo} sx={{ height: '10vh' }} />
                        <Box sx={{ marginTop: '2em', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Box sx={{ display: 'flex' }}>
                            <Typography variant="h5" sx={{ fontWeight: 600, color: 'font.subheading' }}>
                              {societyObject.name}
                            </Typography>
                            <Box sx={{ display: 'flex', width:'2em', height:'2em', marginLeft:'1vw', alignItems: 'center', justifyContent: 'center' }}>
                              {
                                societyObject.logo == undefined ? null : 
                                  <img src={'https://drive.google.com/uc?id=' + societyObject.logo.slice(32).slice(0, -17)} style={{ display:'flex', height:'80%', width:'80%', borderRadius:'50%', margin:'auto', verticalAlign:'center' }} />
                              }
                            </Box>
                          </Box>
                          <Box>
                            <IconButton>
                              <FacebookRoundedIcon size="large" onClick={() => window.open(societyObject.social.facebook)} />
                            </IconButton>
                            <IconButton>
                              <LanguageIcon size="large" onClick={() => window.open(societyObject.social.web)} />
                            </IconButton>
                            <IconButton>
                              <InstagramIcon size="large" onClick={() => window.open(societyObject.social.instagram)} />
                            </IconButton>
                          </Box>
                        </Box>
                        <Box sx={{ marginTop: '0.5em' }}>
                          <Typography variant="body2" sx={{ color: 'font.dark' }}>
                            {societyObject.description}
                          </Typography>
                        </Box>
                        {
                          societyObject.responsibilities ? (
                            <Box sx={{ marginTop: '1em' }}>
                              <Typography variant="h6" sx={{ fontWeight: 500, color: 'font.subheading' }}>
                                Responsibilities
                              </Typography>
                              <Box sx={{ marginTop: '0.5em' }}>
                                <Typography variant="body2" sx={{ color: 'font.dark' }}>
                                  {societyObject.responsibilities}
                                </Typography>
                              </Box>
                            </Box>
                          ):null
                        }
                        {
                          societyObject.benefits ? (
                            <Box sx={{ marginTop: '1em' }}>
                              <Typography variant="h6" sx={{ fontWeight: 500, color: 'font.subheading' }}>
                                Benefits
                              </Typography>
                              <Box sx={{ marginTop: '0.5em' }}>
                                <Typography variant="body2" sx={{ color: 'font.dark' }}>
                                  {societyObject.benefits}
                                </Typography>
                              </Box>
                            </Box>
                          ):null
                        }
                        {
                          societyObject.prerequisites && societyObject.prerequisites.length > 0 ? (
                            <Box sx={{ marginTop: '1em' }}>
                              <Typography variant="h6" sx={{ fontWeight: 500, color: 'font.subheading' }}>
                                Prerequisites
                              </Typography>
                              <Box sx={{ marginTop: '0.5em', display: 'flex', wrap: 'flex-wrap' }}>
                                {
                                  societyObject.prerequisites.map((c) => (
                                    <Box key={c} sx={{ backgroundColor: 'boxFill.dark', padding: '1em', borderRadius: '5px', width: '50%' }}>
                                      <Typography variant="body2" sx={{ color: 'white' }}>
                                        {c}
                                      </Typography>
                                    </Box>
                                  ))
                                }
                              </Box>
                            </Box>
                          ):null
                        }
                        {
                          societyObject.selection_details && societyObject.selection_details.length > 0 ? (
                            <Box sx={{ marginTop: '1em' }}>
                              <Typography variant="h6" sx={{ fontWeight: 500, color: 'font.subheading' }}>
                                Selection Timeline
                              </Typography>
                              <Box sx={{ marginTop: '0.5em', display: 'flex', wrap: 'flex-wrap' }}>
                                <Timeline position="alternate">
                                  {
                                    societyObject.selection_details.map(point => (
                                      <TimelineItem key={point} sx={{ justifyContent: 'left', alignItems:'left' }}>
                                        <TimelineSeparator>
                                          <TimelineDot />
                                          <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                          <Typography varient="body2" sx={{ color: 'font.dark' }}>
                                            {point}
                                          </Typography>
                                        </TimelineContent>
                                      </TimelineItem>
                                    ))
                                  }
                                </Timeline>
                              </Box>
                            </Box>
                          ):null
                        }
                      </Box>
                    ): null
                  }
                </Grid>
              </Grid>
            ):null
          }
          {
            window.innerWidth < 600 ? (
              <Box>
                <Box>
                  <Stack direction="row" sx={{ maxHeight: '100vh', overflowY: 'scroll', ...styleList }} spacing={2}>
                    {
                      studentGroups.map(society => (
                        <Box key={society.name} onClick = {() => setSociety(society)}>
                          <SocietyPageCard name={society.name} backgroundLink={society.bg} logoLink={society.logo} sx={{ height: '10vh' }} />
                        </Box>
                      ))
                    }
                  </Stack>
                </Box>
                <Box>
                  {
                    societyObject ? (
                      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '2em' }}>
                        <SocietyPageCard name={societyObject.name} backgroundLink={societyObject.bg} logoLink={societyObject.logo} sx={{ height: '10vh' }} />
                        <Box sx={{ marginTop: '2em', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Box sx={{ display: 'flex' }}>
                            <Typography variant="h5" sx={{ fontWeight: 600, color: 'font.subheading' }}>
                              {societyObject.name}
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex' }}>
                            <IconButton>
                              <FacebookRoundedIcon size="large" onClick={() => window.open(societyObject.social.facebook)} />
                            </IconButton>
                            <IconButton>
                              <LanguageIcon size="large" onClick={() => window.open(societyObject.social.web)} />
                            </IconButton>
                            <IconButton>
                              <InstagramIcon size="large" onClick={() => window.open(societyObject.social.instagram)} />
                            </IconButton>
                          </Box>
                        </Box>
                        <Box sx={{ marginTop: '0.5em' }}>
                          <Typography variant="body2" sx={{ color: 'font.dark' }}>
                            {societyObject.description}
                          </Typography>
                        </Box>
                        {
                          societyObject.responsibilities ? (
                            <Box sx={{ marginTop: '1em' }}>
                              <Typography variant="h6" sx={{ fontWeight: 500, color: 'font.subheading' }}>
                                Responsibilities
                              </Typography>
                              <Box sx={{ marginTop: '0.5em' }}>
                                <Typography variant="body2" sx={{ color: 'font.dark' }}>
                                  {societyObject.responsibilities}
                                </Typography>
                              </Box>
                            </Box>
                          ):null
                        }
                        {
                          societyObject.benefits ? (
                            <Box sx={{ marginTop: '1em' }}>
                              <Typography variant="h6" sx={{ fontWeight: 500, color: 'font.subheading' }}>
                                Benefits
                              </Typography>
                              <Box sx={{ marginTop: '0.5em' }}>
                                <Typography variant="body2" sx={{ color: 'font.dark' }}>
                                  {societyObject.benefits}
                                </Typography>
                              </Box>
                            </Box>
                          ):null
                        }
                        {
                          societyObject.prerequisites && societyObject.prerequisites.length > 0 ? (
                            <Box sx={{ marginTop: '1em' }}>
                              <Typography variant="h6" sx={{ fontWeight: 500, color: 'font.subheading' }}>
                                Prerequisites
                              </Typography>
                              <Box sx={{ marginTop: '0.5em', display: 'flex', wrap: 'flex-wrap' }}>
                                {
                                  societyObject.prerequisites.map((c) => (
                                    <Box key={c} sx={{ backgroundColor: 'boxFill.dark', padding: '1em', borderRadius: '5px', width: '50%', m:'1%' }}>
                                      <Typography variant="body2" sx={{ color: 'white' }}>
                                        {c}
                                      </Typography>
                                    </Box>
                                  ))
                                }
                              </Box>
                            </Box>
                          ):null
                        }
                        {
                          societyObject.selection_details && societyObject.selection_details.length > 0 ? (
                            <Box sx={{ marginTop: '1em' }}>
                              <Typography variant="h6" sx={{ fontWeight: 500, color: 'font.subheading' }}>
                                Selection Timeline
                              </Typography>
                              <Box sx={{ marginTop: '0.5em', display: 'flex', wrap: 'flex-wrap' }}>
                                <Timeline position="alternate">
                                  {
                                    societyObject.selection_details.map(point => (
                                      <TimelineItem key={point} sx={{ justifyContent: 'left', alignItems:'left' }}>
                                        <TimelineSeparator>
                                          <TimelineDot />
                                          <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                          <Typography varient="body2" sx={{ color: 'font.dark' }}>
                                            {point}
                                          </Typography>
                                        </TimelineContent>
                                      </TimelineItem>
                                    ))
                                  }
                                </Timeline>
                              </Box>
                            </Box>
                          ):null
                        }
                      </Box>
                    ): null
                  }
                </Box>
              </Box>
            ):null
          }
        </Container>

        {/* <Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', width:'100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width:'35%', overflowY: 'scroll', height:'100vh' }}>
              {
                studentGroups.map(society => (
                  <Box key={society.name} sx={{ width:'100%', padding:'2.5%' }} onClick = { () => {
                    setSociety(society);
                    console.log(society);
                  }}>
                    <SocietyPageCard name={society.name} backgroundLink={society.bg} logoLink={society.logo} sx={{ height: '10vh' }}>
                    </SocietyPageCard>
                  </Box>
                ))
              }
            </Box>
            {(societyObject==null?<Box sx={{ display: 'flex', flexDirection:'column', width:'100%',overflowY: 'scroll',  height:'100vh' }}></Box>:
              (
                <Box sx={{ display: 'flex', flexDirection:'column', width:'100%',overflowY: 'scroll',  height:'100vh' }}>
                  <Box sx={{ marginTop:'2.5%' }}><SocietyPageCard name={societyObject.name} backgroundLink={societyObject.bg} logoLink={societyObject.logo} sx={{ width:'80%', display: 'flex', height: '20vh', textAlign: 'center', alignItems: 'center', justifyContent: 'center', margin:'0 auto', fontSize:'5vmin  !important' }} /></Box>
                  <Box sx={{ display: 'flex', flexDirection:'row', justifyContent: 'center', alignItems: 'left',  alignContent: 'center' }}>
                    <Typography variant="h1" component="div" sx={{ display:'flex', color: 'black', fontWeight: 800, textAlign:'left', fontSize:'3vw !important',justifyContent: 'center', alignItems: 'center' }}>
                      {societyObject.name}
                    </Typography>
                    <IconButton>
                      <FacebookRoundedIcon size="large" onClick={() => window.open(societyObject.social.facebook)} />
                    </IconButton>
                    <IconButton>
                      <LanguageIcon size="large" onClick={() => window.open(societyObject.social.web)} />
                    </IconButton>
                    <IconButton>
                      <InstagramIcon size="large" onClick={() => window.open(societyObject.social.instagram)} />
                    </IconButton>
                  </Box>
                  <Box sx={{ display:'flex', flexDirection:'column', alignItems: 'center', justifyContent:'center', textAlign:'center', width:'100%' }}>
                    <Typography variant="body1" component="h2" sx={{ fontSize:'1.5vw ', width: '90%' }}>
                      {societyObject.description}
                    </Typography>
                    <Typography variant="h2" component="div" sx={{ display:'flex', color: 'black', fontWeight: 800,  textAlign:'left', fontSize:'2vw  !important' }}>
                              Responsibilities
                    </Typography>
                    <Typography variant="body1" component="h2" sx={{ fontSize:'1.5vw ', width: '90%' }}>
                      {societyObject.responsibilities}
                    </Typography>
                    <Typography variant="h2" component="div" sx={{ display:'flex', color: 'black', fontWeight: 800,  textAlign:'left', fontSize:'2vw  !important' }}>
                              Benefits
                    </Typography>
                    <Typography variant="body1" component="h2" sx={{ fontSize:'1.5vw ', width: '90%' }}>
                      {societyObject.benefits}
                    </Typography>
                    <Typography variant="h2" component="div" sx={{ display:'flex', color: 'black', fontWeight: 800,  textAlign:'left', fontSize:'2vw  !important' }}>
                              Prerequisites
                    </Typography>
                    <Box sx={{ width:'80%', display: 'flex', justifyContent:'center', alignItems:'content', alignContent:'center' }}>

                      <Box sx={{ width:'100%' }}>
                        <List>
                          {societyObject.prerequisites.map( point => (
                            <ListItem key={point}>
                              <ListItemIcon>
                                <CheckCircleOutlineIcon />
                              </ListItemIcon>
                              <ListItemText
                                primary={point}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    </Box>
                    <Typography variant="h2" component="div" sx={{ display:'flex', color: 'black', fontWeight: 800, textAlign:'left', fontSize:'2vw  !important' }}>
                              Selection Timeline
                    </Typography>
                    <Box sx={{ dispaly: 'flex', justifyContent: 'left', alignItems:'left', width: '80%', alignContent: 'left', textAlign:'left' }}>
                      <Timeline position="alternate">
                        {societyObject.selection_details.map( point => (
                          <TimelineItem key={point} sx={{ justifyContent: 'left', alignItems:'left' }}>
                            <TimelineSeparator>
                              <TimelineDot />
                              <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>{point}</TimelineContent>
                          </TimelineItem>
                        ))}
                      </Timeline>
                    </Box>
                  </Box>
                </Box>
              ))}
          </Box>
        </Box> */}
      </ThemeProvider>
    </>
  );
};

function SocietyListPage(){
  return(
    <div>
      <StudentPage />
    </div>
  );
}

export default SocietyListPage;
