import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import remarkGfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';


const AbstractDataPage = () => {
  const { name } = useParams();

  const data = useSelector(state => {
    const filtered = state.abstractData.filter(abs_data => abs_data.name === name);
    console.log(state.abstractData);
    if (filtered.length > 0) return filtered[0];
    console.log(filtered);
    return null;
  });

  return (
    <Box sx={{ width: '100%', mt: '15vh' }}>
      {
        data ? (
          <Container>
            <Box sx={{ display:'flex', flexDirection:'column', width:['90%', '30%'], height:'auto', justifyContent:'center' }}>
              <Typography variant="h4" sx={{ fontWeight:'600' }}>{data.heading}</Typography>
            </Box>
            <ReactMarkdown remarkPlugins={[remarkGfm]}>{data.description}</ReactMarkdown>
          </Container>
        ) : null
      }
    </Box>    
  );
};

export default AbstractDataPage;
