import React from 'react';
import KgpFundaeMainCard from '../components/KgpFundaeMainCard';
import InspiringKGPiansSlideShow from '../components/InspiringKGPiansSlideShow';
import SocietyMainCard from '../components/SocietyMainCard';
import StudyMaterialHome from '../components/StudyMaterialHome';
import TopDiv from '../components/TopDiv';
import DepCMainCard from '../components/DepcMainCard';
import KGPLingo from '../components/KGPLingo';
import CompetitionsMainCard from '../components/CompetitionsMainCard';
import SafetyAwaressnessHomePageCard from '../components/SafetyAwarenessHomeCard';
import { useSelector } from 'react-redux';

const HomePage = function () {
  const { studentGroups, coc, studySubjects, kgpFundae, departmentChangeTestimonials, inspiringKgpians, competitions, lingos } = useSelector(store => store);

  return (
    <div style={{ backgroundColor: 'rgba(208, 226, 255, 0.2)' }}>
      <div style={{ backgroundColor: 'rgba(243, 243, 243, 1)' }}>
        <TopDiv />
        {

          studySubjects == null || studySubjects.length == 0 ? null : (
            <div
              style={{ marginTop: '1em', marginBottom: '1em' }}
            >
              <StudyMaterialHome />
            </div>
          )
        }
        {
          studentGroups == null || studentGroups.length == 0 ? null : (
            <div
              style={{ marginTop: '1em', marginBottom: '1em' }}
            >
              <SocietyMainCard />
            </div>
          )
        }
        {
          inspiringKgpians == null || inspiringKgpians.length == 0 ? null :
            <div
              style={{ marginTop: '3em', marginBottom: '3em' }}
            >
              <InspiringKGPiansSlideShow />
            </div>
        }
        {
          departmentChangeTestimonials == null || departmentChangeTestimonials.length == 0 ? null : <DepCMainCard />
        }
        {
          kgpFundae == null || kgpFundae.length == 0 ? null : (
            <div
              style={{ marginTop: '1em', marginBottom: '1em' }}
            >
              <KgpFundaeMainCard />
            </div>
          )
        }
        {
          coc == null || coc.length == 0 ? null :
            <div
              style={{ marginTop: '3em', marginBottom: '3em' }}
            >
              <SafetyAwaressnessHomePageCard />
            </div>
        }
        {
          competitions == null || competitions.length == 0 ? null : <CompetitionsMainCard />
        }
        {
          lingos == null || lingos.length == 0 ? null :
            <div
              style={{ marginTop: '3em', marginBottom: '3em' }}
            >
              <KGPLingo />
            </div>
        }
      </div>
    </div>
  );
};

export default HomePage;
