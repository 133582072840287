import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../theme/customTheme';

import StudyMaterialPageItem from '../components/StudyMaterialPageItem';

import { useSelector } from 'react-redux';

import background from '../assets/img/study_material_bg.png';

const StudyMaterial = () => {
  const { studySubjects } = useSelector((state => state));
  return (<ThemeProvider theme={theme}>
    <Box sx={{ display: 'flex', width: '100%', backgroundImage: `url(${background})`, minHxeight: '100vh', backgroundRepeat: 'no-repeat', backgroundPosition: 'right top', margin: 'auto', justifyContent: 'bottom', alignItems: 'bottom', alignContent: 'center', marginTop: '4em' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '60%', minHeight: '80vh', margin: 'auto', justifyContent: 'space-between' }}>
        <Box sx={{ marginTop: '4%' }}>
          <Typography variant="h4" sx={{ fontWeight: '700' }}>Study Material &#128218;</Typography>
          <Typography variant="body2" component="span">Link to all the{' '}<Typography variant="body2" component="span" sx={{ color: 'font.subheading' }}>assignments, theory, reference books, notes, class notes, previous year question papers, lab report</Typography>{', '} and a lot more.</Typography>
        </Box>
        {
          studySubjects.length == 0 ? null :
            <>
              <Box sx={{ width: '100%', marginTop: '20px' }}>
                <Typography variant="h5" sx={{ display: 'flex', justifyContent: ['center', 'left'], alignItems: ['center', 'left'], alignContent: ['center', 'left']}}>Physics Semester</Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'left space-evenly', alignContent: 'left', alignItems: 'left', width: '100%' }}>
                  {
                    studySubjects.map(subject => (
                      (subject.semester == 'physics' ? <StudyMaterialPageItem subName={subject.name} key={subject._id} subLink={subject.url} sx={{ height: '20vmin', width: ['45%', '29%'], marginTop: '5%' }} /> : null)
                    ))
                  }
                </Box>
              </Box>
              <Box sx={{ marginTop: '40px' }}>
                <Typography variant="h5" sx={{ display: 'flex', justifyContent: ['center', 'left'], alignItems: ['center', 'left'], alignContent: ['center', 'left']}}>Chemistry Semester</Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'left space-evenly', alignContent: 'left', alignItems: 'left', width: '100%' }}>
                  {
                    studySubjects.map(subject => (
                      (subject.semester == 'chemistry' ? <StudyMaterialPageItem subName={subject.name} key={subject._id} subLink={subject.url} sx={{ height: '20vmin', width: ['45%', '29%'], marginTop: '5%' }} /> : null)
                    ))
                  }
                </Box>
              </Box>
            </>
        }
      </Box>
    </Box>
  </ThemeProvider>);
};

function StudyMaterialPage() {
  return (
    <div>
      <StudyMaterial />
    </div>
  );
}

export default StudyMaterialPage;