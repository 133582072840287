import { getAll } from '../service';

const kgpFundaePorReducer = (state = [], action = {}) => {
  switch (action.type) {
    case 'INIT_KGP_FUNDAE_PORS': {
      return action.data;
    }
    default: {
      return state;
    }
  }
};

export const initialiseKgpFundaePors = (institute) => async (dispatch) => {
  let data = await getAll('kgp_fundae/por')();
  data = data.filter((por) => por.institute === institute);
  dispatch({
    type: 'INIT_KGP_FUNDAE_PORS',
    data,
  });
};

export default kgpFundaePorReducer;
